import { useEffect, useState,useRef } from "react"
import { callBackend } from "./api";
import { DialogModal } from "./DialogModal";
import { Dropdown } from "./Dropdown";
import { PwdForm } from "./PwdForm";
import { Transactions } from "./Transactions";
import { TrModal } from "./TrModal";
import { determineBrowser } from "./determineBrowser";
import { useHistory } from "react-router-dom"



export const BankingPage = ({onLogout}) => {

    const history = useHistory()

    history.push('/BankingPage')

    const [accountInfo,setAccountInfo] = useState()
    const [accErrMessage, setAccError] = useState()
    const [envelopes,setEnvelopes] = useState()
    const [envErrMessage, setEnvError] = useState()
    const [catName,setCatName] = useState('');
    const [budget,setBudget] = useState('');
    const [errorMessageAddEnv, setErrorMessageAddEnv] = useState()
    const [envAction,setEnvAction] = useState('Choose action')
    const [envCategoryIdx,setEnvCategoryIdx] = useState(-1)
    const [envAmount,setEnvAmount] = useState('')
    const [errorMessageEnvChange,setErrorMessageEnvChange] = useState()
    const [remEnvIdx, setRemEnvIdx] = useState()
    const [remTrIdx, setRemTrIdx] = useState(null)
    const [showForm,setShowForm] = useState(false)
    const [showTr,setShowTr] = useState(false)
    const [showCrTr,setShowCrTr] = useState(false)
    const [maxZ,setMaxZ] = useState(10)
    const [fullWidth,setFullWidth]=useState(window.innerWidth)
    const [transactions,setTransactions] = useState()
    const [trErrMessage, setTrError] = useState()

    const ref = useRef()

    const rootFont = 16

    const handleLoad =  () => {
        const stubHeight = 14.859375
        const fullHeight  = window.innerHeight/rootFont
        const remHeight = fullHeight - stubHeight
        const envHeight = Math.max(remHeight-0.5,14.1)
        if(ref.current!==null){
            ref.current.style.setProperty('height',`${envHeight}rem`)
        }
    }
    
    async function loadTransactions(){
        try {
            setTransactions(await callBackend('users/transactioninfo','GET',
            {}))
        }
        catch (ex) {

            setTransactions(null)

            if(ex.code===401){
                onLogout()
            }

            else if (ex.details && ex.details.message){
                setTrError(ex.details.message)
            }
            else if (ex.message){
                setTrError(ex.message)
            }
            else{
                setTrError('Error')
            }
        }
    }

    async function loadEnvelopes(){
        try {
            setEnvelopes(await callBackend('users/envelopeinfo','GET',{}))
        }
        catch (ex) {

            if(ex.code===401){
                onLogout()
            }

            else if (ex.details && ex.details.message){
                setEnvError(ex.details.message)
            }
            else if (ex.message){
                setEnvError(ex.message)
            }
            else{
                setEnvError('Error')
            }
        }
    }
    
    useEffect(() => {

		(async () => {
			
            try {
                setAccountInfo(await callBackend('users/account','GET',{}))
                await loadEnvelopes()
                await loadTransactions()
                handleLoad()
            }
            catch (ex) {

                if(ex.code===401){
                    onLogout()
                }

                else if (ex.details && ex.details.message){
                    setAccError(ex.details.message)
                }
                else if (ex.message){
                    setAccError(ex.message)
                }
                else{
                    setAccError('Error')
                }
            }
            
		})()

	},[])
    
    useEffect(()=>{
        window.addEventListener('resize',
            function(){setFullWidth(window.innerWidth);handleLoad()},true)

        return () => {
            window.removeEventListener('resize',
                function(){setFullWidth(window.innerWidth);handleLoad()},true);
        };

    },[])

    async function handleLogOut() {
        
        try{
            await callBackend('users/logout','GET',{})
            onLogout()
            
        }
        catch(ex){
            if (ex.details && ex.details.message==='You are already logged out.'){
                onLogout()
            }
            else{
                console.log(ex)
            }
        }
      }


    const handleAddEnv = async e => {
        e.preventDefault();
        if(isNaN(Number(budget))){
            setErrorMessageAddEnv('Budget amount must be a number.')
            return
        }
        if(Number(budget)<0){
            setErrorMessageAddEnv('Amount must be positive.')
            return
        }
        if(['deposit','withdraw',''].includes(catName)){
            setErrorMessageAddEnv("Category name can't be 'deposit,'withdraw',or ''.")
            return
        }
        if (catName.length>128){
            setErrorMessageAddEnv("Category name too long.")
            return
        }
        try{
            const res = await callBackend('users/addenvelope','POST',{},
            {category:catName,budget:budget})
            setBudget('')
            setCatName('')
            setErrorMessageAddEnv()
            setEnvelopes([...envelopes,{id:res.envId,category:catName,
                budget:budget===''?'0':budget,spent:'0'}])
            res.balance = accountInfo.balance
            res.userName = accountInfo.userName
            delete res['envId']
            setAccountInfo(res)
        }
        catch(ex){

            if(ex.code===401){
                onLogout()
            }

            else if (ex.details && ex.details.message && ex.details.message.detail){
                if(ex.details.message.detail.includes('Key (user_id, category)')){
                    setErrorMessageAddEnv('Envelope with this category already exists.')
                }
                else{
                    setErrorMessageAddEnv(ex.message)
                }
            }

            else if (ex.details && ex.details.message){
                setErrorMessageAddEnv(ex.details.message)
            }
            else if (ex.message){
                setErrorMessageAddEnv(ex.message)
            }
            else{
                setErrorMessageAddEnv('Error')
            }
        }
           
    }

    const handleRemEnv = (index) => async (e) => {
        
        e.preventDefault()

        const env = envelopes[index]
        const remId = env.id
        const remBud = env.budget
        const remSpent = env.spent

        try{
            const res = await callBackend('users/removeenvelope','POST',{},
            {eId:remId,budget:remBud,spent:remSpent})
            setRemEnvIdx()
            setEnvelopes(envelopes.slice(0, index).concat(envelopes.slice(index+1)))
            res.userName = accountInfo.userName
            setAccountInfo(res)
            await loadTransactions()
        }
        catch(ex){

            if(ex.code===401){
                onLogout()
            }

            else{
                console.log(ex)
            }
        }
    }

    const handleRemTr = (index) => async (e) => {
            
        e.preventDefault()

        const tr = transactions[index]
        const remId = tr.id
        const eId = tr.envId
        const amount = tr.amount
        const category=tr.category

        try{
            const res = await callBackend('users/removetransaction','POST',{},
            {trId:remId,amount:amount,eId:eId,category:category})
            setRemTrIdx(null)
            setTransactions(transactions.slice(0, index).concat(transactions.slice(index+1)))
            res.userName = accountInfo.userName
            if(category!=='deposit' && category!=='withdraw'){
                loadEnvelopes()
            }
            setAccountInfo(res)
        }
        catch(ex){

            if(ex.code===401){
                onLogout()
            }

            else{
                console.log(ex)
            }
        }
    }

    const handleEnvChange = async e => {
        e.preventDefault();
        if(isNaN(Number(envAmount))){
            setErrorMessageEnvChange('Amount must be a number.')
            return
        }
        if(Number(envAmount)<=0){
            setErrorMessageEnvChange('Amount must be positive.')
            return
        }

        if(envAction==='Choose action'){
            setErrorMessageEnvChange('Please choose an action.')
            return
        }

        if(Number(envCategoryIdx)===-1){
            setErrorMessageEnvChange('Please choose a category.')
            return
        }

        const envIdx = Number(envCategoryIdx)
        const url='users/updateenvelope';
        let body;
        const envId = envelopes[envIdx].id
        const envCategory = envelopes[envIdx].category
        const budget = envelopes[envIdx].budget
        const spent = envelopes[envIdx].spent
            
        if(envAction==='Add money'){
            body={eId:envId,deltaBudget:envAmount,budget:budget,spent:spent}
        }
        else{
            body={eId:envId,deltaBudget:`-${envAmount}`,budget:budget,spent:spent}
        }
            
        try{
            const res = await callBackend(url,'POST',{},body)
            setEnvAmount('')
            setEnvCategoryIdx(-1)
            setEnvAction('Choose action')
            setErrorMessageEnvChange()
            res.userName = accountInfo.userName
            res.balance = accountInfo.balance
            setEnvelopes([...envelopes.slice(0,envIdx),
            {id:envId,category:envCategory,
                budget:res['envBudget'],spent:res['envSpent']},
            ...envelopes.slice(envIdx+1)
            ])
            delete res['envBudget']
            delete res['envSpent']
            setAccountInfo(res)
        }
        
        catch(ex){

            if(ex.code===401){
                onLogout()
            }

            else if (ex.details && ex.details.message){
                setErrorMessageEnvChange(ex.details.message)
            }
            else if (ex.message){
                setErrorMessageEnvChange(ex.message)
            }
            else{
                setErrorMessageEnvChange('Error')
            }

            

        }
            
    }

    let headerTextAcc;
    let displayAccount = false;
    if (!accountInfo){
        headerTextAcc=accErrMessage
    }
    else{
        displayAccount=true
    }
      
    let headerTextEnv;
    let displayEnvelopes = false;
    let displayEnvChangeForm = false;
    if (!envelopes){
        headerTextEnv=envErrMessage
    }
    else{
        displayEnvelopes=true
        if(envelopes.length>2){
            displayEnvChangeForm = true;
        }
    }

    const accountDict = {
        userName:"Email: ",
        balance:"Balance: $",
        availableBalance:"Avaialable Balance: $"
    }

    function calcPos (index){
        if (fullWidth<1210){
            const row = index
            const top = row * 15
            const width = Math.min(fullWidth*0.9,37*rootFont)
            const left = (fullWidth-rootFont-width)/2
            return {width:`${width}px`,top:`${top}rem`,left:`${left}px`}
        }
    
        const row = Math.floor(index/2)
        const col = index %2
        const width = Math.min(fullWidth*0.45,37*rootFont)
        const marginMiddle = 0.06 *width
        const top = row * 15
        const left = col*(width+marginMiddle)+(fullWidth-rootFont-2*width-marginMiddle)/2
        return {width:`${width}px`,top:`${top}rem`,left:`${left}px`}
    }

    function calcPosPwd(){
        if (fullWidth<1210){
            const width_no_pad=379/16*rootFont
            const padRight= Math.min(fullWidth-rootFont-width_no_pad,13.62*rootFont)
            if (padRight<0){
                if (padRight>-2.39*rootFont+2){
                    return [{'padding-right':`0px`},{'margin-left':`2px`},{},
                        'Show password']
                }
                return [{'padding-right':`0px`},{'margin-left':`2px`},{},
                    'Show']
                
            }
            return [{'padding-right':`${padRight}px`},{},{},'Show password']
        }
        return [{},{},calcPosAcc(),'Show password']
    }
    const [stylePwdInput,stylePwdForm,stylePwdDiv,checkBoxText] = calcPosPwd()

    function calcPosEnvInput(){
        let width;
        if (fullWidth<1210){
            width = Math.min(fullWidth*0.9,37*rootFont)
        }
        else{
            width=Math.min(fullWidth*0.45,37*rootFont)
        }
        const inputWidth= 175.8/16*rootFont
        const margin = (width-inputWidth)/2
        return {'margin-left':`${margin}px`}
    }

    function calcPosAcc(){
        if(fullWidth<1210){
            const right=fullWidth*0.0113
            return {right:`${right}px`}
        }
        const width = Math.min(fullWidth*0.45,37*rootFont)
        const marginMiddle = 0.06 *width
        const right = (fullWidth-rootFont-2*width-marginMiddle)/2
        return {right:`${right}px`}

    }

    function calcMaxWidthAccInfo(){
        if(fullWidth<1210){
            let right=fullWidth*0.0113
            right+=10*rootFont
            const maxWidth=fullWidth-right
            return {'max-width':`${maxWidth}px`}
        }
        return {}
    }
    
    return(
        <>  
            <div 
                className="Header"
                style={{visibility:showForm?"hidden":"visible"}}
            >
                Account Info
            </div>
            {headerTextAcc && <h2>{headerTextAcc}</h2>}

            {
                displayAccount && 
                <div 
                    id="AccContainer"
                    style={Object.assign({visibility:showForm?"hidden":"visible"},
                        calcMaxWidthAccInfo()
                    )}
                >
                    {
                        Object.keys(accountDict).map(
                            (a)=><span key={a}>
                                {accountDict[a]}{accountInfo[a]}
                            </span>
                        )
                    }

                </div>
            }

            
            <div className="Header">Envelopes</div> 
            {headerTextEnv && <h2>{headerTextEnv}</h2>}
            {
                displayEnvChangeForm &&
                <form className="env_change_form" onSubmit={handleEnvChange} >
                    <select value={envAction} 
                    onChange={e => setEnvAction(e.target.value)}>
                        <option value={'Choose action'}>Choose action</option>
                        <option value={'Add money'}>Add money</option>
                        <option value={'Remove money'}>Remove money</option>
                    </select>
                    <select value={envCategoryIdx} 
                    onChange={e => setEnvCategoryIdx(e.target.value)}>
                        <option value={-1}>
                            Choose category
                        </option>
                        {
                            envelopes.slice(2).map((e,indx)=>
                                <option key={e.id} value={indx+2}>
                                    {e.category}
                                </option>
                            )
                        }
                    </select>
                    <input type="text" placeholder="Amount" 
                    onChange={e => setEnvAmount(e.target.value)} value={envAmount}/>
                    <input type="submit" value="Execute"/>
                    {errorMessageEnvChange && <span className="formError">
                        {errorMessageEnvChange} </span>}
                </form>
            }

            {
                displayEnvelopes && 
                <div id="envContainer" ref={ref}>
                    {envelopes.slice(2).map(
                        (e,index)=>
                        <div 
                            className="env" 
                            key={e.id}
                            style={calcPos(index)}
                        >
                            <div className="env_h_cat">Category: {e.category}</div>
                            <div className="env_headers">
                                <span className="env_header">Budget: ${e.budget}</span>
                                <span className="env_header">Spent: ${e.spent}</span>
                            </div>
                            <button className="remEnv" onClick={()=>setRemEnvIdx(index+2)}>
                                x
                            </button>
                        </div>
                    )}
                    <div 
                        className="env"
                        style={calcPos(envelopes.length-2)}
                    >
                        <form 
                            onSubmit={handleAddEnv} 
                            style={calcPosEnvInput()}
                        >
                            <input 
                                type="text" 
                                placeholder="Category" 
                                onChange={e => setCatName(e.target.value)} value={catName}
                            /><br/>
                            <input 
                                type="text" 
                                placeholder="Budget"
                                onChange={e => setBudget(e.target.value)} 
                                value={budget}
                            /><br/>
                            <input 
                                type="submit" 
                                value="Add Envelope"
                            />   
                            {errorMessageAddEnv && <span className="formError">
                                {errorMessageAddEnv} </span>}
                        </form>
                    </div>
                </div>
            }
            <button 
                id="trButton" 
                onClick={()=>{setShowCrTr(true)}}
                style={calcPosAcc()}
            > 
                Add Transaction 
            </button>
            <Dropdown 
                id={"accountDropPos"}
                trigger = {<button>Account</button>}
                menu = {[
                    <button 
                        onClick={()=>{setShowTr(true)}}

                    > 
                        See transactions 
                    </button>,
                    <button onClick={()=> setShowForm(true)}> Change password </button>,
                    <button onClick={handleLogOut}> Logout </button>
                ]}
                menuId = {"accountMenu"}
                style={calcPosAcc()}
            />

            {
                showForm&& 
                <PwdForm
                    id = {"pwdForm"}
                    setShowForm={setShowForm}
                    onLogout={onLogout}
                    styleInput = {stylePwdInput}
                    styleForm={stylePwdForm}
                    stylePwdDiv={stylePwdDiv}
                    checkBoxText={checkBoxText}
                />
            }

            {
                showCrTr&&
                <TrModal
                    loadTransactions={loadTransactions}
                    envelopes = {envelopes}
                    setEnvelopes = {setEnvelopes}
                    accountInfo = {accountInfo}
                    setAccountInfo = {setAccountInfo}
                    onLogout = {onLogout}
                    setShowCrTr = {setShowCrTr}
                    maxZ={maxZ}
                    setMaxZ = {setMaxZ}
                />
            }

            {
                showTr&&
                <Transactions 
                    transactions={transactions}
                    trErrMessage={trErrMessage}
                    setRemTrIdx={setRemTrIdx}
                    setShowTr={setShowTr}
                    maxZ={maxZ}
                    setMaxZ={setMaxZ}
                />
            }

            {   
                remEnvIdx &&
                <DialogModal
                    onProceed={handleRemEnv(remEnvIdx)}
                    onClose={() => setRemEnvIdx()}
                >
                    <p>
                        Are you sure you would like to delete the envelope {<br/>} 
                        {<b>{envelopes[remEnvIdx].category}</b>} {' '} 
                        and all concomitant transactions?
                    </p>

                </DialogModal>
            }

            {   
                remTrIdx!==null &&
                <DialogModal
                    onProceed={handleRemTr(remTrIdx)}
                    onClose={() => setRemTrIdx(null)}
                >
                    <p>
                        Are you sure you would like to delete this transaction?
                    </p>

                </DialogModal>
            }
                            
        </>
    )
}