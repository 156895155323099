import { MovableModal } from "./MoveableModal";


export const Transactions = ({transactions,trErrMessage,setRemTrIdx,
    setShowTr,maxZ,setMaxZ}) => {

    let headerTextTr;
    let displayTransactions = false;
    if (!transactions){
        headerTextTr=trErrMessage
    }

    else if(transactions.length===0){
        headerTextTr = 'No transactions'
    }

    else{
        displayTransactions=true
    }

    return(
        <MovableModal
            title={<span>Transactions</span>}
            setShowModal={setShowTr}
            maxZ={maxZ}
            setMaxZ={setMaxZ}
        >

            {headerTextTr && <div id="noTrText">{headerTextTr}</div>}

            {
                displayTransactions &&

                <table>
                    <tr>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Description</th>
                    </tr>
                    {transactions.map((Tr,index)=>
                        <tr key={Tr.id}>
                            <td>{Tr.category}</td>
                            <td>${Tr.amount}</td>
                            <td id="dateText">{Tr.date.split('T')[0]}</td>
                            <td>{<p id="descText">{Tr.description}</p>}</td>
                            <td>
                                {<button 
                                    class="remTr" 
                                    onClick={()=>setRemTrIdx(index)}
                                >
                                    x
                                </button>}
                            </td>
                        </tr>
                    )}
                </table>
            }

        </MovableModal>
    )
    
}